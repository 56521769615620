import React, { useContext } from 'react'
import { graphql } from 'gatsby'

import Seo from './../components/Layout/Seo'
import Collection from './../components/Collection/List'
import InstagramFeed from '../components/Instagram/InstagramFeed'

import { DictionaryContext } from './../contexts/dictionary'

const ClassicsPage = ({
    pageContext: {
        lang,
        langSlug
    },
    data: {
        instagram,
        products,
        collection: {
            data: {
                classics_title,
                classics_description,
                classics_navigation,
                collection_media,
                sizes,
                colors,
                seo_title,
                seo_description,
                seo_keywords,
                seo_image
            }
        }
    }
}) => {

    const dictionary = useContext(DictionaryContext)

    return (
        <>
            <Seo
                title={seo_title}
                description={seo_description.text}
                keywords={seo_keywords}
                image={seo_image}
            />
            <Collection
                lang={langSlug}
                title={classics_title}
                description={classics_description}
                items={products.nodes}
                type='classics'
                navigation={classics_navigation}
                filters={[{
                    type: 'size',
                    placeholder: dictionary.all_sizes,
                    options: sizes
                }, {
                    type: 'color',
                    placeholder: dictionary.all_colors,
                    options: colors
                }]}
                media={collection_media}
            />
            <InstagramFeed
                copy={instagram}
            />
        </>
    )
}

export const QUERY_COLLECTIONPAGE = graphql`
    query ClassicsPage($id: String!, $lang: String!) {
        collection: prismicCollectionPage(id: { eq: $id }) {
            id
            lang
            ...CollectionPageQuery
        }
        products: allPrismicProductItem(
            sort: {
                order: ASC, 
                fields: data___product_title___text
            }, 
            filter: {
                data: {
                    product_type: {
                        eq: "Classics"
                    }
                }, 
                lang: {
                    eq: $lang
                }
            }) {
            nodes {
                uid 
                data {
                    product_title {
                        text
                    }
                    product_family {
                        slug
                        document {
                            ... on PrismicProductFamily {
                                data {
                                    title {
                                        text
                                    }
                                }
                            }
                        }
                    }
                    product_size {
                        document {
                            ... on PrismicProductSize {
                                data {
                                    title {
                                        text
                                    }
                                }
                            }
                        }
                    }
                    small: thumbnail {
                        alt
                        dimensions {
                            width
                            height
                        }
                        fluid(maxWidth: 200) {
                            ...GatsbyPrismicImageFluid
                        }
                    }
                    medium: thumbnail {
                        alt
                        dimensions {
                            width
                            height
                        }
                        fluid(maxWidth: 400) {
                            ...GatsbyPrismicImageFluid
                        }
                    }
                    body {
                        ... on PrismicProductItemBodyColors {
                            primary {
                                color {
                                    document {
                                        ... on PrismicProductColor {
                                            data {
                                                title {
                                                    text
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            items {
                                color
                                month
                            }
                        }
                    }
                }
            }
        }
        instagram: prismicInstagramComponent(lang: { eq: $lang }) {
            id
            lang
            ...InstagramQuery
        }
    }
`

export default ClassicsPage